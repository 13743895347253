:root {
    --dunbar: "DunbarText";
    --ave: "Avenir";
    --avenext: "Avenir Next";
    --fontawsome: "Font Awesome 5 Free";
    --poppins: "Poppins";
    --primary: #EEBC37;
    --white: #ffffff;
    --black: #000000;
    --light: #a0a0a0;
    --danger: #dc3545;
}

/* Fonts */
@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir/Avenir-LightOblique.eot");
    src: url("../fonts/Avenir/Avenir-LightOblique.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/Avenir-LightOblique.woff") format("woff"),
        url("../fonts/Avenir/Avenir-LightOblique.ttf") format("truetype"),
        url("../fonts/Avenir/Avenir-LightOblique.svg#Avenir-LightOblique") format("svg");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir/Avenir-Black.eot");
    src: url("../fonts/Avenir/Avenir-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/Avenir-Black.woff") format("woff"),
        url("../fonts/Avenir/Avenir-Black.ttf") format("truetype"),
        url("../fonts/Avenir/Avenir-Black.svg#Avenir-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir/Avenir-Medium.eot");
    src: url("../fonts/Avenir/Avenir-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/Avenir-Medium.woff") format("woff"),
        url("../fonts/Avenir/Avenir-Medium.ttf") format("truetype"),
        url("../fonts/Avenir/Avenir-Medium.svg#Avenir-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/Avenir/AvenirNext-Regular.eot");
    src: url("../fonts/Avenir/AvenirNext-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/AvenirNext-Regular.woff") format("woff"),
        url("../fonts/Avenir/AvenirNext-Regular.ttf") format("truetype"),
        url("../fonts/Avenir/AvenirNext-Regular.svg#AvenirNext-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/Avenir/AvenirNext-Medium.eot");
    src: url("../fonts/Avenir/AvenirNext-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/AvenirNext-Medium.woff") format("woff"),
        url("../fonts/Avenir/AvenirNext-Medium.ttf") format("truetype"),
        url("../fonts/Avenir/AvenirNext-Medium.svg#AvenirNext-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DunbarText";
    src: url("../fonts/Dunbar/DunbarText-Bold.eot");
    src: url("../fonts/Dunbar/DunbarText-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Dunbar/DunbarText-Bold.woff") format("woff"),
        url("../fonts/Dunbar/DunbarText-Bold.ttf") format("truetype"),
        url("../fonts/Dunbar/DunbarText-Bold.svg#DunbarText-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Medium.eot');
    src: url('../fonts/poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Medium.woff') format('woff'),
        url('../fonts/poppins/Poppins-Medium.ttf') format('truetype'),
        url('../fonts/poppins/Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Bold.eot');
    src: url('../fonts/poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/poppins/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Black.eot');
    src: url('../fonts/poppins/Poppins-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Black.woff') format('woff'),
        url('../fonts/poppins/Poppins-Black.ttf') format('truetype'),
        url('../fonts/poppins/Poppins-Black.svg#Poppins-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Regular.eot');
    src: url('../fonts/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/poppins/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-SemiBold.eot');
    src: url('../fonts/poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/poppins/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: var(--ave);
    font-size: 16px;
    line-height: 1.2em;
    color: var(--black);
}

.mobile-only {
    display: none !important;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.site-header {
    width: 100%;
    padding: 35px 0;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.site-header.fixed {
    background-color: rgba(0, 0, 0, 0.95);
    padding: 25px 0;
}

.site-header .row {
    align-items: center;
}

.site-header .logo {
    position: relative;
}

.site-header .logo img {
    max-width: 190px;
    height: auto;
}

.site-header .logo .logo-vector-img {
    position: absolute;
    bottom: -60px;
    width: 500px;
    z-index: -1;
    max-width: initial;
    left: -240px;
}

.site-header.fixed .logo .logo-vector-img {
    opacity: 0;
}

.navigation-wrapper {
    display: flex;
    flex-direction: column-reverse;
    text-align: right;
    position: relative;
}

.navigation ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navigation ul li {
    list-style: none;
    font-size: 11px;
    color: var(--white);
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.35); */
    margin-left: 15px;
    text-transform: none;
    border: none
}

.navigation ul li a {
    cursor: pointer;
    font-family: var(--poppins);
    font-weight: 500;
    color: #000;
    text-decoration: none;
    font-size: 16px;
}

.site-header.fixed .navigation ul li a {
    color: #fff;
}

.navigation-wrapper .social-icons {
    margin: 0;
    display: inline-block;
}

.navigation-wrapper .login-links ul,
.navigation-wrapper .social-icons ul {
    margin: 0;
    padding: 0;
}

.navigation-wrapper .login-links ul li,
.navigation-wrapper .social-icons ul li {
    display: inline-block;
    list-style: none;
    margin-left: 15px;
    font-size: 14px;
    border: none;
    text-transform: initial;
}

.navigation-wrapper .login-links ul li a,
.navigation-wrapper .social-icons ul li a {
    color: #000;
    font-weight: 400;
    font-size: 15px;
}

.navigation-wrapper .social-icons ul li.social {
    font-size: 22px;
}

.navigation-wrapper .top-menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.navigation-wrapper .login-links {
    margin-right: 0px;
}

.navigation-wrapper .login-links .btn {
    line-height: 1.2em;
    color: #000;
    font-family: var(--poppins);
    border-radius: 25px;
    background-color: var(--primary);
    padding: 15px 25px;
}

.site-header.fixed .navigation-wrapper .login-links .btn {
    color: #000;
}

/* Footer */
.footer-wrapper {
    padding-top: 15px;
    padding-bottom: 10px;
    float: left;
    width: 100%;
    background: var(--black);
}

.footer-wrapper .social-icons ul {
    margin: 0;
    padding: 0;
}

.footer-wrapper .social-icons li {
    display: inline-block;
    margin-right: 10px;
    font-size: 22px;
}

.footer-wrapper .social-icons li a {
    color: #fff;
}

.footer-wrapper .copyright p {
    font-size: 13px;
    color: #fff;
    margin: 0;
    text-align: center;
}

.footer-links {
    text-align: right;
}

.footer-links ul {
    margin: 0;
    padding: 0;
}

.footer-links ul li a {
    color: #fff;
    text-decoration: none;
    font-family: var(--dunbar);
    font-size: 13px;
}

.back-to-top.show {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
}

.back-to-top {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary);
    color: #fff;
    position: fixed;
    bottom: 45px;
    right: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    z-index: 9;
    -webkit-transform: translateY(200%);
    -moz-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

/* Banner */
.banner-wrapper {
    /* background-image: url("../images/banner-img.jpg"); */
    background-color: #F3F2E7;
    background-position: center;
    background-size: cover;
    min-height: 71vh;
    text-align: left;
    position: relative;
    padding: 180px 0 20px 0;
}

.banner-wrapper:after {
    content: "";
    width: 100%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    /* background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); */
}

.banner-wrapper .container {
    z-index: 1;
    /* position: relative; */
}

.banner-img img {
    max-width: 100%;
}

.banner-wrapper .container .row {
    align-items: center;
}

.banner-content {
    margin: 0;
    position: initial;

    left: inherit;
    transform: inherit;
    bottom: inherit;
    z-index: 1;
}

.banner-content h2 {
    font-size: 30px;
    color: #000;
    font-family: var(--dunbar);
    line-height: 1.5em;
    margin: 0 0 20px;
    font-weight: 400;
    text-transform: capitalize;

}

.banner-content h2 span {
    color: #000;
    width: 100%;
    display: inline-block;
    font-size: 70px;
    text-transform: uppercase;
    font-weight: bolder;
    line-height: 1.1em;
    margin: 0 0 5px;
}

.banner-content h3 {
    font-size: 16px;
    color: var(--primary);
    font-family: var(--dunbar);
    line-height: 1.5em;
    margin: 0 0 25px;
}

.banner-content-wrapper {
    margin: 40px 0 0 0;
    text-align: center;
}

.banner-content-wrapper h2 {
    font-size: 18px;
    color: #000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-weight: 400;
    font-family: var(--poppins);
}

.banner-content .btn {
    background-color: var(--primary);
    color: #000;
    border-radius: 25px;
    font-size: 20px;
}

/* About Wrapper */
.about-wrapper {
    padding: 80px 0 30px;
    background-image: url("../images/build-img.png");
    text-align: center;
    position: relative;
}

.about-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: #000000;
    opacity: 0.5;
}

.about-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49763655462184875) 0%, rgba(0, 0, 0, 1) 100%);
}

.about-wrapper .container {
    position: relative;
    z-index: 2;
}

.about-content {
    text-align: center;
    max-width: 560px;
    margin: 50px auto 20px;
    position: relative;
    z-index: 1;
}

.about-content h2 {
    font-size: 25px;
    color: #fff;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-weight: 600;
    font-family: var(--poppins);
}

.about-content p {
    font-size: 18px;
    color: #fff;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-weight: 300;
    font-family: var(--poppins);
}

.about-content .btn {
    margin: 25px 0 50px;
    font-weight: 500;
    background-color: var(--primary);
    color: #000;
    border-radius: 25px;
    font-size: 20px;
}

.about-bottom-content p {
    font-size: 15px;
    color: #fff;
    font-weight: 300;
    font-family: var(--poppins);
}

/* Mobile Content */
.mobile-content-wrapper {
    padding: 60px 0;
    background-color: var(--white);
    /* background-image: url("../images/world-map.png"); */
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    position: relative;
}

.mobile-content-wrapper:after {
    background-color: #fff;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.mobile-content-wrapper .container {
    z-index: 1;
    position: relative;
}

.no-bg-img {
    background-image: none;
}

.headings {
    text-align: center;
    max-width: 767px;
    margin: 0 auto 30px;
}

.headings h2 {
    font-size: 25px;
    color: #000000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.headings h3 {
    font-size: 22px;
    color: #000000;
    margin: 0 0 10px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.headings p {
    font-size: 18px;
    color: #000000;
    margin: 0 0 15px;
    line-height: 1.3em;
}

.mobile-content-wrapper .headings {
    margin-bottom: 50px;
}

.mobile-content h2,
.mobile-content h3 {
    font-size: 25px;
    color: #000000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.mobile-content h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.mobile-content p {
    font-size: 18px;
    color: #000000;
    margin: 0 0 25px;
    line-height: 1.5em;
    max-width: 80%;
}

.mobile-images {
    position: relative;
    text-align: center;
}

.mobile-images>img {
    max-width: 100%;
    max-height: 500px;
}

.mobile-images .rounded-img img {
    width: 450px;
    height: 450px;
    border-radius: 50%;
    object-fit: cover;
}

.mobile-images .app-store-links {
    position: absolute;
    bottom: 15px;
    right: 25px;
    width: 120px;
}

.mobile-images .app-store-links.left-side {
    left: 25px;
    right: initial;
}

.mobile-images .app-store-links img {
    max-width: 100%;
    margin: 0 0 10px;
}

.coming-soon-text {
    color: var(--primary);
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
}

/* Make Difference */
.make-difference-wrapper {
    padding: 80px 0 80px;
    position: relative;
    text-align: center;
}

.make-difference-wrapper-1 {
    padding-bottom: 10px;
}

.make-difference-wrapper .container {
    z-index: 1;
    position: relative;
}

.make-difference-content {
    max-width: 767px;
    margin: 0 auto;
}

.make-difference-content h2 {
    font-size: 25px;
    color: #000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
    text-transform: capitalize;
}

.make-difference-content p {
    font-size: 18px;
    color: #000;
    margin: 0 0 35px;
    line-height: 1.3em;
}

/* Video Content */
.video-content-wrapper {
    padding: 50px 0 80px 0;
}

.video-content .video-frame {
    margin: 0 0 20px;
}

.video-content iframe {
    max-width: 80%;
}

.video-content h3 {
    font-size: 20px;
    color: #000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.card-details>label {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin: 0 0 10px;
}

.price-title {
    margin: 25px 0;
    text-align: center;
}

.price-title h3 {
    font-size: 16px;
    line-height: 1.4em;
    font-weight: 600;
    margin: 0;
}

.checkbox-wrapper {
    margin-top: 10px;
    display: inline-block;
    margin-right: 10px;
}

.checkbox-wrapper input[type="radio"],
.checkbox-wrapper input[type="checkbox"] {
    display: none;
}

.checkbox-wrapper .checkbox {
    border: 1px solid #dedede;
    width: 25px;
    height: 25px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 15px;
}

.checkbox-wrapper input[type="radio"]:checked+.checkbox,
.checkbox-wrapper input[type="checkbox"]:checked+.checkbox {
    background: #dedede;
    position: relative;
}

.checkbox-wrapper input[type="radio"]:checked+.checkbox:after,
.checkbox-wrapper input[type="checkbox"]:checked+.checkbox:after {
    content: "\f00c";
    font-family: var(--fontawsome);
    font-weight: 900;
    font-size: 11px;

    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.checkbox-wrapper label {
    display: flex;
    align-items: center;
    color: var(--black);
    font-weight: 500;
}

.select-wrapper {
    position: relative;
}

.select-wrapper:after {
    position: absolute;
    content: "\f078";
    top: 50%;
    right: 15px;
    font-family: var(--fontawsome);
    font-weight: 900;
    transform: translate(0, -50%);
    z-index: 1;
}

.aggrement-check {
    margin: 0 0 20px;
}

.aggrement-check .checkbox-wrapper label {
    font-size: 16px;
    line-height: 1.4em;
}

.aggrement-check .checkbox-wrapper .checkbox {
    width: 90px;
    height: 30px;
    margin-right: 20px;
    margin-left: 10px;
}

/* Our Process */
.our-process-wrapepr {
    margin-top: 0px;
    padding: 80px 0;
    background-image: url("../images/build-img-2.png");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    text-align: center;
}

.our-process-wrapepr:after {
    background-color: #3C9776;
    opacity: 0.92;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.our-process-wrapepr .container {
    position: relative;
    z-index: 2;
}

.our-process-wrapepr .headings {
    width: 550px;
    max-width: 80%;
    margin-bottom: 70px;
}

.our-process-wrapepr .headings p,
.our-process-wrapepr .headings h3,
.our-process-wrapepr .headings h2 {
    color: #fff;
}

.content-btn {
    margin: 40px 0 0 0;
    text-align: center;
}

.content-btn .btn {
    background-color: var(--primary);
    border-radius: 25px;
    font-size: 18px;
    color: #000;
}

.process-wrap {
    position: relative;
    width: 900px;
    max-width: 80%;
    margin: 0 auto;
}

.process-wrap::after {
    content: "";
    width: 80%;
    border-bottom: 2px dashed #c2c2c2;
    opacity: 0.2;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 65px;
    left: 50%;
    z-index: 1;

    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
}

.process-item {
    text-align: center;
}

.process-item-img {
    width: 185px;
    height: 135px;
    margin: 0 auto 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.process-item-img img {
    max-height: 85%;
    margin-top: auto;
}

/* .process-item-img .number {
    width: 45px;
    height: 45px;
    background-color: var(--primary);
    border-radius: 50%;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    font-family: var(--nutino);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: initial;
    opacity: 1;
    right: -21px;
    top: 50%;
    z-index: 1;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
} */
.process-item-content h2 {
    font-size: 23px;
    color: #fff;
    margin: 0 0 10px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.process-item-content p {
    font-size: 18px;
    line-height: 1.3em;
    font-family: var(--nutino);
    color: #fff;
    text-transform: capitalize;
    margin: 0;
}

.faq-wrapper {
    margin: 0;
    padding: 80px 0;
    background-color: #242424;
}

.faq {
    max-width: 80%;
    margin: 0 auto;
    width: 750px;
}

.faq-wrapper h2 {
    margin-bottom: 25px;
    color: #fff;
}

.faq-wrapper .accordion .accordion-item,
.accordian .card {
    border: none;
    border-radius: 0;
    background-color: #313131;
    margin: 0 0 15px;
    border-radius: 9px;
    box-shadow: 3px 7px 7px rgba(0, 0, 0, 0.3);
}

.faq-wrapper .accordion .accordion-header,
.accordian .card-header {
    padding: 15px 15px;
    font-size: 18px;
    font-weight: 400;
    border: none;
}

.faq-wrapper .accordion-header button,
.accordian .card-header a {
    color: var(--secondary-color);
    width: 100%;
    display: inline-block;
    padding-right: 30px;
    position: relative;
    text-decoration: none;
}

.faq-wrapper .accordion-header button:before,
.accordian .card-header a:before {
    position: absolute;
    top: 0;
    right: 0;
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--secondary-color);
}

.faq-wrapper .accordion-header button[aria-expanded="false"]:before,
.accordian .card-header a[aria-expanded="false"]:before {
    content: "\f054";
}

.faq-wrapper .accordion-header button[aria-expanded="true"]:before,
.accordian .card-header a[aria-expanded="true"]:before {
    content: "\f078";
}

.faq-wrapper .accordion-header button:focus,
.faq-wrapper .accordion-header button:hover,
.accordian .card-header a:focus,
.accordian .card-header a:hover {
    color: var(--secondary-color);
    text-decoration: none;
}

.faq-wrapper .accordion-body,
.accordian .card-header h5,
.accordian .card-body {
    line-height: 1.5em;
    font-size: 18px;
    padding: 1.25rem;
    color: #fff;
}

.faq-wrapper .accordion-header {
    margin-bottom: 0;
}

.reverse-column {
    flex-direction: row-reverse;
}

/* Testimionials */
.testimonial-wrapper {
    padding: 40px 0;
    background-color: #F3F2E7;
}

.testimonial-wrapper .headings {
    width: 500px;
    max-width: 80%;
}

.slide-item {
    padding: 30px 25px;
    border-radius: 25px;
    background-color: #fff;
    margin-bottom: 30px;
}

.slide-item .content {
    font-size: 14px;
    line-height: 1.3em;
    margin: 0 0 10px;
}

.slick-dots {
    bottom: -35px;
}

.slick-dots li {
    width: auto;
    height: auto;
}

.slick-dots li button {
    padding: 0;
}

.slick-dots li button:before {
    content: "";
    width: 15px;
    height: 15px;
    border: 3px solid #000;
    border-radius: 50%;
    opacity: 0.2;
}

.slick-dots li.slick-active button:before {
    background-color: #000;
}

.testimonial-content {
    text-align: center;
}

.testimonial-content h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 5px;
    line-height: 1.4em;
    color: #000;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.testimonial-content p {
    font-size: 14px;
    margin: 0 0 10px;
    line-height: 1.4em;
    color: #000;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.testimonial-thumb {
    margin: 0 0 25px;
    position: relative;
    cursor: pointer;
}

.testimonial-thumb img {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    object-fit: cover;
}

.testimonial-slider {
    text-align: center;
}

.testimonial-slider .user-name {
    font-size: 14px;
    margin: 15px 0 0;
    font-family: var(--ave);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.testimonial-slider .user-name .user-img {
    margin: 0 5px 0 0;
}

.testimonial-slider .user-name img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #cfcfcf;
    object-fit: cover;
    background: #fff;
    padding: 3px;
}

.testimonial-slider .view-all-btns {
    display: inline-block;
    margin: 25px 0 15px 0;
    color: #000;
    text-decoration: none;
    line-height: 1.3em;
    border-bottom: 1px solid #000;
}

.play-icon {
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #b5b5b5;
}

.video-wrapper {
    width: 100%;
    height: 250px;
    border-radius: 10px;

    background: rgb(229, 229, 229);
    background: radial-gradient(circle, rgba(229, 229, 229, 1) 0%, rgba(180, 180, 180, 1) 63%);

    background: #d6d6d6;
}

.testimonial-slider .slick-list {
    margin: 0 5px;
}

.testimonial-slider .slick-slider+div {
    z-index: 11;
}

.testimonial-slider .slick-prev,
.testimonial-slider .slick-arrow {
    z-index: 2;
}

.testimonial-slider .slick-prev:before,
.testimonial-slider .slick-next:before {
    font-size: 25px;
    color: #000;
}

.testimonial-slider .slick-next {
    right: -16px;
}

.testimonial-slider .slick-prev {
    left: -20px;
}

.fg-testimonial-wrapper {
    text-align: center;
    background-color: var(--primary);
    position: relative;
    padding: 100px 0;
}

.fg-testimonial-wrapper:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    z-index: 1;
    opacity: 0.91;
    position: absolute;
    background-size: cover;
    background-color: transparent;
    background-image: url("../images/yello-bg-img.png");
}

.fg-testimonial-wrapper .container {
    position: relative;
    z-index: 2;
}

.fg-testimonial-wrapper .mobile-content {
    max-width: 450px;
    margin: 0 auto;
}

.fg-testimonial-wrapper .user-img {
    margin-bottom: 30px;
}

.fg-testimonial-wrapper .user-img img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid #fff;
}

.fg-testimonial-wrapper .mobile-content p {
    max-width: 100%;
    text-align: center;
}

.impect-greenbox-wrapper {
    text-align: center;
    margin: 50px 0 60px;
}

.impect-greenbox-wrapper .greenbox-item {
    background-color: #3C9776;
    border-radius: 10px;
    padding: 25px 20px;
}

.impect-greenbox-wrapper .greenbox-item h3 {
    color: #fff;
    font-size: 20px;
    margin: 0 0 15px;
}

.impect-greenbox-wrapper .greenbox-item p {
    color: #fff;
    font-size: 18px;
    margin: 0 0 0px;
}

.scrollToTop {
    width: 50px;
    height: 50px;
    background: var(--primary);
    box-shadow: 0 0 30px rgba(255, 53, 130, 0.3);
    color: #fff;
    font-size: 25px;
    border-radius: 50%;
    position: fixed;
    bottom: 95px;
    right: 15px;
    z-index: 99;
    cursor: pointer;
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-transition: all 0.5s ease-in 0s;
    -moz-transition: all 0.5s ease-in 0s;
    transition: all 0.5s ease-in 0s;
}

.scrollToTop.show {
    opacity: 1;
}