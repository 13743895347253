@media(max-width:1025px) {
    .admin-wrapper {
        padding: 0;
    }
    .admin-wrapper.open {
        padding: 0;
    }
    .sidebar-menu-wrapper.open{
        z-index: 8;
    }
    .sidebar-menu-wrapper:not(.open) {
        transform: translateX(-100%);
    }
    .admin-header {
        padding: 10px 10px 10px 70px; 
    }
    .admin-wrapper:not(.open) .hamberger {
        
        --webkit-transform: translateX(0);
        transform: translateX(0);
    }
    .profile-wrapper span.closeIcon {
        display: inline-block;
        position: absolute;
        right: 13px;
        top: 23px;
        z-index: 4;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
    }    
    .modal.large .modal-dialog {
        max-width: 95%;
    }
    .listing-tables {
        overflow-x: auto;
    }
}

@media(max-width:767px) {
    .app-logo-wrapper .app-name {
        font-size: 11px;
    }
    .admin-body > .container {
        padding: 0 15px;
    }
    /* .services-wrapper .col-12 {
        flex: 0 0 auto;
        width: 50%;
    } */
    .services-wrapper .col-12:last-child,
    .services-wrapper .col-12:first-child {
        display: none;
    }
    .device-info .row {
        flex-direction: column;
    }
    .info-table {
        margin-bottom: 15px;
    }
    .straming-wrapper .row {
        
    }
    .cctv-map img {
        max-width: 100%;
    }
    .listing-tables {
        overflow-x: auto;
    }
    .auth-page .form-wrapper {
        width: 85%;
    }
}