@media (max-width: 1169px) {
    .site-header {
        padding: 20px 15px;
    }
    .site-header.fixed {
        padding: 20px 15px;
    }
    .hambergar {
        width: 35px;
        height: 35px;
        position: absolute;
        z-index: 3;
        display: inline-block;
        top: 50px;
        right: 20px;
        cursor: pointer;
    }
    .hambergar span {
        width: 100%;
        height: 4px;
        float: right;
        background: var(--primary);
        margin: 0 0 7px;
        border-radius: 3px;
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
    }
    .hambergar span:last-child {
        margin: 0;
    }
    .navigation-wrapper li {
        width: 100%;
        text-align: center;
        margin: 0;
        border-bottom: 1px solid #ccc;
    }

    .navigation-wrapper .navigation {
        position: fixed;
        padding: 125px 0 0;
        width: 420px;
        max-width: 90%;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        height: 100vh;
        z-index: 2;
        opacity: 0;
        background: rgba(0, 0, 0, 0.9);
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);

        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        transform: translateX(100%);
    }
    .navigation-wrapper.open .navigation {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        transform: translateX(0);
    }
    .navigation ul {
        display: initial;
        width: 100%;
    }
    .navigation ul li {
        width: 100%;
        text-align: center;
        margin: 0;
        border-bottom: 1px solid #ccc;
    }
    .navigation-wrapper li:first-child {
        border-top: 1px solid #ccc;
    }
    .navigation-wrapper li a:not(.btn) {
        width: 100%;
        display: inline-block;
        padding: 20px 10px;
        color: #fff;
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .banner-content h2 {
        font-size: 23px;
    }
    .make-difference-content,
    .about-content {
        width: 80%;
    }
}

@media (max-width: 767px) {
    .make-difference-content,
    .about-content,
    .banner-content {
        width: 90%;
    }
    .banner-content h2 {
        font-size: 19px;
    }
    .banner-content h3 {
        font-size: 14px;
    }
    .headings h2,
    .about-content h2 {
        font-size: 19px;
    }
    .about-content p {
        font-size: 16px;
    }
    .site-header .social-icons {
        position: absolute;
        top: 42%;
    }

    .mobile-content {
        text-align: center;
        margin-bottom: 25px;
    }
    .mobile-content h2,
    .mobile-content h3 {
        font-size: 20px;
    }
    .mobile-images .app-store-links {
        width: 150px;
    }
    .make-difference-content h2 {
        font-size: 20px;
    }
    .make-difference-content p {
        font-size: 18px;
    }
    .footer-wrapper {
        text-align: center;
    }
    .mobile-footer-icons {
        margin: 10px 0 10px;
    }
    .footer-links {
        margin: 10px 0 10px;
        text-align: center;
    }
    .back-to-top {
        bottom: 45px;
        right: 25px;
    }

    .our-process-wrapepr {
        padding: 40px 0 40px 0;
    }
    .process-item {
        margin-bottom: 30px;
    }
    .process-wrap::after {
        display: none;
    }
}
