:root {
    --dunbar: "DunbarText";
    --ave: "Avenir";
    --avenext: "Avenir Next";

    --fontawsome: "Font Awesome 5 Free";
    --primary: #EEBC37;
    --white: #ffffff;
    --black: #000000;
    --light: #666;
    --danger: #dc3545;
}

/* Fonts */
@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir/Avenir-LightOblique.eot");
    src: url("../fonts/Avenir/Avenir-LightOblique.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/Avenir-LightOblique.woff") format("woff"),
        url("../fonts/Avenir/Avenir-LightOblique.ttf") format("truetype"),
        url("../fonts/Avenir/Avenir-LightOblique.svg#Avenir-LightOblique") format("svg");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir/Avenir-Black.eot");
    src: url("../fonts/Avenir/Avenir-Black.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/Avenir-Black.woff") format("woff"),
        url("../fonts/Avenir/Avenir-Black.ttf") format("truetype"),
        url("../fonts/Avenir/Avenir-Black.svg#Avenir-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir";
    src: url("../fonts/Avenir/Avenir-Medium.eot");
    src: url("../fonts/Avenir/Avenir-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/Avenir-Medium.woff") format("woff"),
        url("../fonts/Avenir/Avenir-Medium.ttf") format("truetype"),
        url("../fonts/Avenir/Avenir-Medium.svg#Avenir-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/Avenir/AvenirNext-Regular.eot");
    src: url("../fonts/Avenir/AvenirNext-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/AvenirNext-Regular.woff") format("woff"),
        url("../fonts/Avenir/AvenirNext-Regular.ttf") format("truetype"),
        url("../fonts/Avenir/AvenirNext-Regular.svg#AvenirNext-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/Avenir/AvenirNext-Medium.eot");
    src: url("../fonts/Avenir/AvenirNext-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Avenir/AvenirNext-Medium.woff") format("woff"),
        url("../fonts/Avenir/AvenirNext-Medium.ttf") format("truetype"),
        url("../fonts/Avenir/AvenirNext-Medium.svg#AvenirNext-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "DunbarText";
    src: url("../fonts/Dunbar/DunbarText-Bold.eot");
    src: url("../fonts/Dunbar/DunbarText-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/Dunbar/DunbarText-Bold.woff") format("woff"),
        url("../fonts/Dunbar/DunbarText-Bold.ttf") format("truetype"),
        url("../fonts/Dunbar/DunbarText-Bold.svg#DunbarText-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: var(--ave);
    font-size: 15px;
    line-height: 1.2em;
    color: var(--black);
    margin: 0;
    padding: 0;
}

.select-wrapper {
    position: relative;
}

.select-wrapper:after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #000;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    line-height: 1em;
}

.form-group {
    margin: 0 0 15px;
}

.form-group label {
    display: inline-block;
    margin-bottom: 7px;
    font-size: 12px;
    font-family: var(--ave);
    font-weight: 500;
    color: #000;
}

form .form-control,
form input[type="text"],
form input[type="email"],
form input[type="password"],
form textarea {
    width: 100%;
    outline: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-family: var(--ave);
    font-size: 14px;
    box-shadow: none;
    background-color: #fff;
    border-color: var(--borders);
    color: #000;
}

select.form-control {
    -webkit-appearance: initial;
    -moz-appearance: initial;
    appearance: initial;
}

.form-control:hover,
.form-control:focus,
input[type="text"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
textarea:hover {
    box-shadow: none !important;
    background-color: #fff;
    border-color: var(--borders);
    color: #000;
}

form .inputWithIcon .form-control .input[type="submit"],
.input[type="button"],
.btn {
    padding: 10px 30px;
    background-color: var(--primary);
    color: #000;
    font-size: 14px;
    font-family: var(--dunbar);
    border-radius: 20px;
    border-color: var(--primary);
}

.form-check {
    display: flex;
    align-items: center;
}

.form-check label {
    margin-left: 10px;
    margin-bottom: 0;
}

.form-check input[type="checkbox"] {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-top: -2px;
}

.form-check input[type="checkbox"]:checked {
    background-color: var(--primary);
    border-color: var(--primary);
    background-image: none;
}

.form-check input[type="checkbox"]:checked:after {
    content: "\f00c";
    color: #000;
    font-family: var(--fontawsome);
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
}

.form-check .form-check-input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-top: -2px;
}

.form-check-input[type="radio"]:checked {
    background-color: #000;
    border-color: #000;
}

.input[type="submit"]:focus,
.input[type="button"]:focus,
.input[type="submit"]:hover,
.input[type="button"]:hover,
.btn:active,
.btn:focus,
.btn:hover {
    background-color: transparent;
    color: #000;
    border-color: var(--primary);
    box-shadow: none !important;
}

.btn.btn-small {
    padding: 10px 15px;
}

.btn.btn-dark {
    border-color: #000;
    background-color: #000;
    color: #fff;
}

.btn.btn-dark:hover,
.btn.btn-dark:focus {
    background-color: transparent;
    color: #000;
}

.btn.black-hover:focus,
.btn.black-hover:focus,
.btn.black-hover:hover {
    color: #000;
}

.btn.btn-danger {
    border-color: #000;
    color: #fff;
    background-color: var(--danger);
}

.btn.btn-outline {
    border-color: var(--primary);
    color: #fff;
    background-color: transparent;
}

.btn.btn-outline:hover {
    background-color: var(--primary);
    color: #000;
}

.form-control.error {
    border-color: var(--danger);
}

.info-text {
    width: 100%;
    display: inline-block;
    margin-top: 8px;
    font-size: 14px;
    color: var(--light);
    line-height: 1.3em;
    word-break: break-word;
}

.info-text.error {
    color: var(--danger);
}

.info-text a {
    color: #BA871C;
    text-decoration: underline;
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon .form-control {
    padding-left: 35px;
}

.inputWithIcon i {
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.inputWithIcon.right-side .form-control {
    padding-left: 15px;
    padding-right: 30px;
}

.inputWithIcon.right-side i {
    right: 15px;
    left: inherit;
}

.select-box {
    position: relative;
}

.select-box:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 10px;
    content: "";
    background-color: #000;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.back-btn {
    margin-right: 10px;
}

.alert-dismissible .btn-close {
    font-size: 12px;
}

.alert p {
    font-size: 12px;
    margin: 0;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.as-link {
    cursor: pointer;
}

.no-margin {
    margin: 0;
}

.fieldset {
    margin: 0 0 15px;
}

form label {
    font-size: 14px;
    color: var(--light);
    margin: 0 0 5px;
    display: inline-block;
    width: 100%;
    line-height: 1.2em;
}

.mobile-only {
    display: none !important;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #000;
    z-index: 10;
}

.loader-logo {
    position: relative;
}

.loader-logo img {
    max-width: 130px;
}

.loader-logo:after {
    width: 45px;
    height: 45px;
    content: "";
    background-image: url("../images/loader.gif");
    background-size: 100% 100%;
    position: absolute;
    top: 110%;
    left: 50%;

    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.site-header {
    width: 100%;
    padding: 20px 0;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.site-header.fixed {
    background-color: rgba(0, 0, 0, 0.95);
    padding: 15px 0;
}

.site-header .row {
    align-items: center;
}

.site-header .logo img {
    max-width: 110px;
    height: auto;
}

.navigation-wrapper {
    display: flex;
    flex-direction: column-reverse;
    text-align: right;
}

.navigation ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navigation ul li {
    list-style: none;
    font-size: 11px;
    color: var(--white);
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
    margin-left: 15px;
    text-transform: uppercase;
}

.navigation ul li a {
    cursor: pointer;
    font-family: var(--avenext);
    font-weight: normal;
    color: white;
    text-decoration: none;
}

.social-wrapper .social-icons {
    margin-bottom: 10px;
}

.social-icons ul {
    margin: 0;
    padding: 0;
}

.social-icons ul li {
    display: inline-block;
    list-style: none;
    margin-right: 10px;
    font-size: 22px;
}

.social-icons ul li a {
    color: #fff;
}

/* Footer */
.footer-wrapper {
    padding-top: 15px;
    padding-bottom: 10px;
    float: left;
    width: 100%;
    background: var(--black);
}

.footer-wrapper .social-icons ul {
    margin: 0;
    padding: 0;
}

.footer-wrapper .social-icons li {
    display: inline-block;
    margin-right: 10px;
    font-size: 22px;
}

.footer-wrapper .social-icons li a {
    color: #fff;
}

.footer-wrapper .copyright p {
    font-size: 13px;
    color: #fff;
    margin: 0;
    text-align: center;
}

.footer-links {
    text-align: right;
}

.footer-links ul {
    margin: 0;
    padding: 0;
}

.footer-links ul li a {
    color: #fff;
    text-decoration: none;
    font-family: var(--dunbar);
    font-size: 13px;
}

.back-to-top.show {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
}

.back-to-top {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary);
    color: #fff;
    position: fixed;
    bottom: 45px;
    right: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    z-index: 9;
    -webkit-transform: translateY(200%);
    -moz-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

/* Banner */

.banner-wrapper {
    background-position: center;
    background-size: cover;
    min-height: 450px;
    text-align: center;
    position: relative;
}

.banner-wrapper:after {
    content: "";
    width: 100%;
    height: 101%;
    position: absolute;
    top: 0;
    left: 0;

    /* background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%); */
}

.banner-wrapper .container {
    z-index: 1;
    /* position: relative; */
}

.banner-content {
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 50px;
    z-index: 1;
}

.banner-content h2 {
    font-size: 30px;
    color: var(--white);
    font-family: var(--dunbar);
    line-height: 1.3em;
    margin: 0 0 10px;
}

.banner-content h3 {
    font-size: 16px;
    color: var(--primary);
    font-family: var(--dunbar);
    line-height: 1.5em;
    margin: 0 0 25px;
}

/* About Wrapper */
.about-wrapper {
    padding: 80px 0;
    background-color: var(--black);
}

.about-content {
    text-align: center;
    max-width: 767px;
    margin: 0 auto;
}

.about-content h2 {
    font-size: 25px;
    color: #fff;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.about-content p {
    font-size: 18px;
    color: #fff;
    margin: 0 0 15px;
    line-height: 1.3em;
}

/* Mobile Content */
.mobile-content-wrapper {
    padding: 60px 0;
    background-color: var(--white);
    background-image: url("../images/world-map.png");
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    position: relative;
}

.mobile-content-wrapper:after {
    background-color: #fff;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.mobile-content-wrapper .container {
    z-index: 1;
    position: relative;
}

.headings {
    text-align: center;
    max-width: 767px;
    margin: 0 auto 30px;
}

.headings h2 {
    font-size: 25px;
    color: #000000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.headings h3 {
    font-size: 22px;
    color: #000000;
    margin: 0 0 10px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.headings p {
    font-size: 18px;
    color: #000000;
    margin: 0 0 15px;
    line-height: 1.3em;
}

.mobile-content-wrapper .headings {
    margin-bottom: 50px;
}

.mobile-content h2,
.mobile-content h3 {
    font-size: 23px;
    color: #000000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.mobile-content p {
    font-size: 16px;
    color: #000000;
    margin: 0 0 25px;
    line-height: 1.5em;
}

.mobile-images {
    position: relative;
    text-align: center;
}

.mobile-images .app-store-links {
    position: absolute;
    bottom: 15px;
    right: 25px;
    width: 120px;
}

.mobile-images .app-store-links img {
    max-width: 100%;
    margin: 0 0 10px;
}

.coming-soon-text {
    color: var(--primary);
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
}

/* Make Difference */
.make-difference-wrapper {
    padding: 80px 0 80px;
    position: relative;
    text-align: center;
}

.make-difference-wrapper .container {
    z-index: 1;
    position: relative;
}

.make-difference-content {
    max-width: 767px;
    margin: 0 auto;
}

.make-difference-content h2 {
    font-size: 25px;
    color: #000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
    text-transform: capitalize;
}

.make-difference-content p {
    font-size: 18px;
    color: #000;
    margin: 0 0 35px;
    line-height: 1.3em;
}

/* Video Content */
.video-content-wrapper {
    padding: 50px 0 80px 0;
}

.video-content .video-frame {
    margin: 0 0 20px;
}

.video-content iframe {
    max-width: 80%;
}

.video-content h3 {
    font-size: 20px;
    color: #000;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

/* Modal Style */
.modal-backdrop.show {
    opacity: 0.8;
    color: #242424;
}

.modal .modal-content {
    border-radius: 0;
    border: 0;
}

.modal .close {
    font-size: 25px;
    opacity: 1;
}

.card-details>label {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin: 0 0 10px;
}

.price-title {
    margin: 25px 0;
    text-align: center;
}

.price-title h3 {
    font-size: 16px;
    line-height: 1.4em;
    font-weight: 600;
    margin: 0;
}

.checkbox-wrapper {
    margin-top: 10px;
    display: inline-block;
    margin-right: 10px;
}

.checkbox-wrapper input[type="radio"],
.checkbox-wrapper input[type="checkbox"] {
    display: none;
}

.checkbox-wrapper .checkbox {
    border: 1px solid #dedede;
    width: 25px;
    height: 25px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 15px;
}

.checkbox-wrapper input[type="radio"]:checked+.checkbox,
.checkbox-wrapper input[type="checkbox"]:checked+.checkbox {
    background: #dedede;
    position: relative;
}

.checkbox-wrapper input[type="radio"]:checked+.checkbox:after,
.checkbox-wrapper input[type="checkbox"]:checked+.checkbox:after {
    content: "\f00c";
    font-family: var(--fontawsome);
    font-weight: 900;
    font-size: 11px;

    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.checkbox-wrapper label {
    display: flex;
    align-items: center;
    color: var(--black);
    font-weight: 500;
}

.select-wrapper {
    position: relative;
}

.select-wrapper:after {
    position: absolute;
    content: "\f078";
    top: 50%;
    right: 10px;
    font-family: var(--fontawsome);
    font-weight: 900;
    transform: translate(0, -50%);
    z-index: 1;
}

.aggrement-check {
    margin: 0 0 20px;
}

.aggrement-check .checkbox-wrapper label {
    font-size: 16px;
    line-height: 1.4em;
}

.aggrement-check .checkbox-wrapper .checkbox {
    width: 90px;
    height: 30px;
    margin-right: 20px;
    margin-left: 10px;
}

/* Our Process */
.our-process-wrapepr {
    margin-top: 30px;
    padding: 80px 0;
    background-image: url("../images/bg-img.jpg");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    text-align: center;
}

.our-process-wrapepr:after {
    background-color: #000;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.our-process-wrapepr .container {
    position: relative;
    z-index: 2;
}

.our-process-wrapepr .headings {
    width: 550px;
    max-width: 80%;
}

.our-process-wrapepr .headings p,
.our-process-wrapepr .headings h3,
.our-process-wrapepr .headings h2 {
    color: #fff;
}

.process-wrap {
    position: relative;
    width: 900px;
    max-width: 80%;
    margin: 0 auto;
}

.process-wrap::after {
    content: "";
    width: 80%;
    border-bottom: 2px dashed #c2c2c2;
    opacity: 0.2;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 65px;
    left: 50%;
    z-index: 1;

    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
}

.process-item {
    text-align: center;
}

.process-item-img {
    width: 135px;
    height: 135px;
    margin: 0 auto 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.process-item-img .number {
    width: 45px;
    height: 45px;
    background-color: var(--primary);
    border-radius: 50%;
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    font-family: var(--nutino);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: initial;
    opacity: 1;
    right: -21px;
    top: 50%;
    z-index: 1;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.process-item-content h2 {
    font-size: 23px;
    color: #fff;
    margin: 0 0 15px;
    line-height: 1.3em;
    font-family: var(--dunbar);
}

.process-item-content p {
    font-size: 18px;
    line-height: 1.3em;
    font-family: var(--nutino);
    color: #fff;
}

.user-dropdown-wrapper a {
    color: #fff;
    text-decoration: none;
}

/* Login Auth Page */
.auth-page-wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #242424;
    align-items: center;
    justify-content: center;
}

.auth-page-container {
    position: relative;
    z-index: 3;
    padding: 45px 45px;
    background: #F3F2E7;
    border-radius: 25px;
    width: 400px;
    max-width: 80%;
}

.auth-logo {
    text-align: left;
    margin-bottom: 15px;
}

.auth-logo img {
    max-width: 180px;
}

.auth-logo h3 {
    color: #000;
    font-size: 20px;
    margin: 0 0 7px;
    font-family: var(--ave);
    font-weight: 600;
}

.auth-logo p {
    font-size: 14px;
}

.auth-form form .form-control:focus,
.auth-form form .react-tel-input .form-control,
.auth-form form .form-control {
    width: 100%;
    height: auto;
    max-width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    color: #000;
    padding: 8px 0 8px 0;
    font-size: 13px !important;
}

.auth-form form .form-control option {
    background-color: transparent;
    color: #000;
}

.auth-form form .react-tel-input .form-control {
    padding-left: 48px;
}

form .react-tel-input .form-control {
    padding-left: 48px;
    height: auto;
    width: 100%;
    max-width: 100%;
    line-height: 1.4em;
    border-radius: 20px;
}

.auth-page-container .form-control::placeholder {
    color: #aaa;
}

.auth-form .form-group {
    margin-bottom: 15px;
}

.auth-form .btn {
    margin-top: 10px;
    text-transform: none;
    font-size: 14px;
    letter-spacing: 0.12em;
    padding: 7px 20px;
    border-radius: 20px;
}

.auth-form .btn:hover {
    color: #000;
}

.auth-form form .form-label {
    font-size: 11px;
    line-height: 1.4em;
    color: #fff;
    margin: 0 0 5px;
}

form .react-tel-input .flag-dropdown.open .selected-flag,
form .react-tel-input .selected-flag:hover,
form .react-tel-input .selected-flag:focus,
form .react-tel-input .flag-dropdown:hover,
form .react-tel-input .flag-dropdown:focus,
form .react-tel-input .flag-dropdown.open,
form .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: none;
}

.auth-form form .react-tel-input .selected-flag .arrow {
    border-top-color: #fff;
}

.auth-form form .react-tel-input .flag-dropdown.open .selected-flag .arrow {
    border-bottom-color: #fff;
}

form .react-tel-input .selected-flag .arrow {
    border-top-color: #000;
}

form .react-tel-input .flag-dropdown.open .selected-flag .arrow {
    border-bottom-color: #000;
}

.otp-wrapper {
    display: flex;
    justify-content: space-between;
}

.otp-wrapper .form-group {
    margin-right: 15px;
}

.otp-wrapper .form-group:last-child {
    margin-right: 0;
}

.otp-wrapper .form-control {
    text-align: center;
}

.update-number {
    width: 100%;
    font-size: 13px;
    display: inline-block;
    margin-top: 10px;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    line-height: 1.5em;
}

.social-icons {
    display: flex;
}

.social-icons .icons {
    margin: 5px 8px 5px 0;
    cursor: pointer;
    width: 20px;
    height: 20px;
    text-align: center;
}

.social-icons.light .icons {
    color: #000;
}

.social-icons .icons.active {
    color: var(--primary);
}

/* Home Page */
.my-impact-headings {
    text-align: center;
    color: #000;
    margin-top: 20px;
}

.my-impact-headings .payment-text {
    margin: 0 5px 10px;
    font-weight: 500;
    display: inline-block;
    font-size: 20px;
}

.contribution-wrapper h2 {
    font-size: 28px;
    margin: 0 0 15px;
}

.my-impact-headings .payment-text>span {
    color: #000;
    margin-right: 3px;
    display: inline-block;
    font-weight: 600;
}

.appretiation-text {
    width: 85%;
    max-width: 450px;
    margin: 0 auto;
}

.appretiation-text .btn {
    font-weight: 500;
    padding: 10px 25px;
    font-family: var(--poppins);
    font-size: 15px;
    margin-top: 10px;
}

.months-wrapper .heading {
    display: flex;
    border-bottom: 1px solid var(--borders);
    padding: 15px 0;
    margin: 0;
    width: 100%;
}

.months-wrapper .heading h2 {
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    text-transform: initial;
    margin: 0;
}

.months-wrapper .heading .distributed-text {
    font-size: 14px;
    color: #fff;
}

.cuases-lists-wrapper {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
}

.list-wrapper {
    margin: 15px 0 0;
}

.list-wrapper h3 {
    font-size: 16px;
    color: #000;
    margin: 0 0 15px;
}

.list-item {
    padding: 20px 25px;
    border-radius: 20px;
    background: #fff;
    margin-bottom: 20px;
}

.list-item .cover-thumb {
    width: 100%;
    margin-bottom: 20px;
}

.list-item .cover-thumb img {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    height: 170px;
}

.user-info {
    display: flex;
}

.user-info img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
}

.list-description .name-wrapper .as-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

.list-description .name-wrapper h2 {
    font-size: 20px;
    color: #000;
    margin: 0 0 5px;
    font-weight: 600;
    font-family: var(--avenext);
}

.list-description .name-wrapper h3 {
    font-size: 15px;
    color: #000;
    margin: 0 0 5px;
    font-weight: 400;
    font-family: var(--avenext);
}

.list-description .name-wrapper p {
    font-size: 15px;
    color: #000;
    margin: 0 0 10px;
    font-weight: 400;
    font-family: var(--avenext);
}

.list-description .name-wrapper .price {
    font-size: 15px;
    color: #000;
    font-weight: 600;
    font-family: var(--avenext);
    display: inline-block;
    margin: 0;
}

.list-description {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.percentage-count {
    color: #000;
    font-size: 15px;
    line-height: 1.2em;
    margin-left: 15px;
}

.user-info-wrapper {
    margin: 10px 0 0px;
    display: flex;
    justify-content: space-between;
}

.user-info-wrapper .user-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.user-info-wrapper .user-info img {
    margin-right: 10px;
}

.user-info-wrapper .user-info span {
    color: #000;
    margin: 0;
    font-size: 14px;
    line-height: 1.2em;
}

.pledge-given {
    font-size: 16px;
    color: #000;
    margin: 0;
}

.pledge-given span {
    font-weight: 600;
    width: 100%;
    display: inline-block;
    margin: 5px 0 0;
}

.pledge-given .btn {
    padding: 10px 25px;
    margin: 0;
    line-height: 1.1em;
    text-align: left;
    font-size: 14px;
    background-color: var(--borders);
    border-color: var(--borders);
    color: #fff;
    width: auto;
}

.pledge-given .btn:hover {
    background-color: var(--borders);
    border-color: var(--borders);
    color: #fff;
}

.pledge-given .btn.active {
    background-color: var(--borders);
    border-color: var(--borders);
    color: #fff;
}

.cause-nomination .btn.active i,
.pledge-given .btn.active i {
    color: var(--primary);
}

.pledge-given .btn i {
    margin-right: 4px;
}

.view-all-link {
    margin: 15px 0;
    font-size: 13px;
    text-transform: uppercase;
}

.view-all-link a {
    color: #000;
    font-weight: 500;
    text-decoration: none;
}

.accordion-body,
.accordion-item {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
}

.accordion-header .accordion-button {
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
}

.accordion-header .accordion-button:after {
    display: none;
}

.profile-img {
    margin-right: 15px;
    position: relative;
}

.profile-img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-btn .btn {
    font-size: 12px;
    padding: 8px 30px;
}

.profile-btn .btn:first-child {
    margin-right: 15px;
}

.upload-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--primary);
    z-index: 1;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    font-size: 12px;
    cursor: pointer;
}

.user-profile-wrapper {
    padding: 40px 45px;
    border: 1px solid #ddd;
    border-radius: 15px;
    text-align: left;
    background-color: #fff;
}

.user-profile-wrapper form .form-control {
    border-color: #ddd;
}

.user-profile-wrapper form .react-tel-input .form-control {
    background: #fff;
    border-color: #ddd;
}

.user-profile-wrapper label {
    text-align: left;
}

.StripeElement {
    background: #fff;
    padding: 15px 20px;
    border-radius: 20px;
    margin: 0;
}

.modal-form-wrapper .form-check {
    margin-right: 15px;
}

.modal-form-wrapper label {
    font-size: 14px;
}

.subdesc-wrapper h2 {
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin: 0 0 5px;
}

.subdesc-wrapper h3 {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}

.subdesc-wrapper p {
    font-size: 14px;
    margin: 0 0 10px;
    color: #000;
}

.subdesc-wrapper p span {
    font-weight: 600;
}

.subdesc-wrapper .btn-danger {
    font-weight: 600;
    font-size: 14px;
    padding: 9px 12px 9px;
    font-family: var(--avenext);
}

.loader-icon {
    width: 100%;
    margin: 50px 0;
    color: #000;
    text-align: center;
    display: inline-block;
    font-size: 20px;
}

.loader-icon i::before,
.loader-icon i {
    font-weight: 900;
}

.cause-details-wrapper .banner-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 250px;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
}

.cause-details-wrapper .banner-img:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: #000;
    opacity: 0.05;
}

.cause-details {
    margin: 10px 0 0;
}

.cause-details h2 {
    color: #000;
    line-height: 1.3em;
    margin: 0 0 5px;
    font-size: 22px;
    font-weight: 600;
    font-family: var(--avenext);
}

.cause-details p {
    font-size: 14px;
    color: #000;
}

.cause-headings {
    color: #000;
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px;
}

.categ-info span {
    font-size: 14px;
}

.regions-info .percentages {
    width: 100%;
    display: inline-block;
    margin-top: 4px;
}

.regions-info {
    font-size: 14px;
    text-align: right;
}

.cause-funding-goal {
    color: #000;
    margin: 0 0 15px;
}

.cause-funding-goal span {
    color: var(--light);
    font-size: 14px;
}

.cause-funding-goal p {
    font-size: 14px;
    margin: 0 0 3px;
}

.cause-nomination {
    color: #000;
    font-size: 13px;
}

.cause-nomination .user-info {
    display: flex;
    align-items: center;
    margin: 20px 0 25px;
}

.cause-nomination .user-info img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.cause-nomination .btn {
    padding: 8px 15px;
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.cause-nomination .btn.active {
    border-color: #000;
    background-color: #000;
    color: #fff;
}

.cause-nomination .btn i {
    margin-right: 5px;
}

.social-icons {
    margin: 8px 0 0;
}

.social-icons ul li {
    font-size: 22px;
}

.social-icons ul li a {
    color: var(--primary);
    text-decoration: none;
}

.filter-form {
    padding: 15px;
    background-color: #171717;
}

.filter-form .form-group {
    margin-right: 15px;
    margin-bottom: 10px;
}

.filter-form .form-group .form-control {
    min-width: 150px;
    padding-right: 35px;
}

.cause-filter-wrapper .filter-form {
    padding: 0;
    background-color: transparent;
    margin-bottom: 15px;
}

.cause-filter-wrapper .filter-form .form-group label {
    margin-bottom: 10px;
    font-size: 14px;
}

.navs-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
}

.navs-wrapper .btn {
    font-weight: 400;
    font-size: 14px;
    padding: 8px 10px;
    line-height: 1.2em;
    font-family: var(--avenext);
    min-width: 150px;
    text-align: left;
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.navs-wrapper .btn .cause-item-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2em;
    font-family: var(--avenext);
    margin: 5px 0 0;
    display: inline-block;
}

.navs-wrapper .btn .nav-text {
    display: flex;
    align-items: center;
}

.navs-wrapper .btn.next {
    margin-left: auto;
}

.navs-wrapper .btn.next .nav-text {
    justify-content: flex-end;
}

.navs-wrapper .btn.next i {
    margin-left: 8px;
}

.navs-wrapper .btn.prev i {
    margin-right: 8px;
}

.causes-loader {
    width: 100%;
    display: inline-block;
    margin: 50px 0 30px;
}

.causes-loader i {
    color: #fff;
    font-size: 20px;
}

.no-results {
    text-align: center;
    margin: 50px 0;
    color: #fff;
    font-size: 18px;
}

.filter-options-wrapper .form-control {
    width: 160px;
}

.add-ratting-wrapper {
    margin: 0 0 0px;
}

.add-ratting-wrapper .heading {
    margin-bottom: 15px;
}

.add-ratting-wrapper .heading h2 {
    color: #fff;
    font-size: 25px;
    margin: 0 0 5px;
    line-height: 1.3em;
}

.add-ratting-wrapper .heading h3 {
    color: #fff;
    font-size: 12px;
    margin: 8px 0 10px;
    line-height: 1.3em;
    font-weight: 500;
    font-family: var(--avenext);
}

.add-ratting-wrapper .heading p {
    color: #fff;
    font-size: 14px;
    line-height: 1.5em;
}

.add-ratting-wrapper .remove-icon {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dc3545;
    color: #fff;
    font-size: 10px;
}

.files-drop-area {
    width: 100%;
    border: 1px dashed var(--borders);
    border-radius: 10px;
    height: 80px;
    position: relative;
    margin-bottom: 20px;
}

.files-drop-area input {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.files-drop-area>span {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #000;
    font-size: 14px;
    font-family: var(--dunbar);

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ratting-wrapper {
    margin: 0 0 20px;
}

.ratting-requests {
    margin: 0 -15px 60px;
    padding: 0;
}

.ratting-requests .alert {
    padding: 25px 25px;
    border-radius: 20px;
    background: #D6D3C7;
    border-color: #D6D3C7;
}

.ratting-requests h3 {
    font-size: 18px;
    margin: 0 0 15px;
    font-weight: 400;
    color: #000;
}

.ratting-requests p {
    font-size: 15px;
    line-height: 1.4em;
    margin: 0 0 15px;
    font-weight: 500;
    padding-right: 25px;
    font-family: var(--ave);
    color: #000;
}

.ratting-requests .ratting-btn-wrapper .btn {
    padding: 12px 20px;
    font-size: 14px;
    margin-right: 10px;
    font-family: var(--ave);
    font-weight: 500;
    line-height: 1.3em;
    border-radius: 35px;
}

.ratting-requests .slick-arrow {
    display: none !important;
}

.ratting-requests ul.slick-dots {
    bottom: -65px;
}

.file-field-wrapper {
    position: relative;
    cursor: pointer;
    margin: 0 0 5px;
}

.file-field-wrapper input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.fileSelected {
    color: #fff;
    margin: 10px 0 0;
    font-size: 12px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.fileSelected img {
    width: auto;
    height: 80px;
    object-fit: cover;
}

.fileSelected>div {
    position: relative;
    display: inline-block;
}

.fileSelected .remove_img {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #dc3545;
    border-radius: 50%;
    font-size: 10px;
    cursor: pointer;
}

.preview-wrapper {
    margin-bottom: 20px;
}

.preview-wrapper .fileSelected {
    margin: 0 0 15px;
}

.example-amounts {
    margin: 5px 0 15px;
}

.example-amounts .btn {
    padding: 8px 25px;
    font-size: 13px;
    margin-right: 8px;
    color: #000;
}

.example-amounts .btn:hover {
    color: #000;
}

.example-amounts .btn.active {
    background-color: var(--primary);
    color: #000;
}

.front-dashboard {
    margin: 25px 0 35px;
}

.home-regions-dropdown {
    width: 170px;
    height: auto;
    outline: none;
}

.home-regions-dropdown select:focus,
.home-regions-dropdown select {
    outline: none;
    box-shadow: none;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    font-family: var(--avenext);
    border-radius: 20px;
    padding: 9px 18px;
}

.remaining-amount-wrapper .fa-spinner {
    color: #000;
    font-size: 20px;
}

.remaining-amount-wrapper .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px;
}

.remaining-amount-wrapper .heading h2 {
    width: 70%;
    font-size: 20px;
    color: #000;
    line-height: 1.3em;
    margin: 0;
}

.remaining-amount-wrapper .heading .months-wrapper {
    width: 30%;
}

.distribution-amount-wrapper .inputWithIcon {
    width: 100px;
}

.distribution-amount-wrapper input[type="text"] {}

.remaining-amount-wrapper .heading h3,
.remaining-amount-data-wrapper h3 {
    margin: 0;
    font-size: 15px;
    color: #000;
}

.remaining-amount-data-wrapper .listing-tables {
    margin: 0;
}

.remaining-amount-data-wrapper .listing-tables tr td {
    padding-right: 25px;
}

.remaining-amount-data-wrapper .listing-tables tr td,
.remaining-amount-data-wrapper .listing-tables tr th {
    border-bottom: 1px solid #ddd;
}

.remaining-amount-data-wrapper .listing-tables tr th {
    padding-left: 25px;
}

.remaining-amount-data-wrapper .listing-tables tr:last-child th {
    padding-bottom: 18px;
}

.remaining-amount-data-wrapper .listing-tables tr:nth-child(odd) td {
    background-color: transparent;
    border-bottom: 1px solid #ddd;
}

.remaining-amount-data-wrapper .listing-tables tr:hover td {
    background-color: transparent;
}

.remaining-amount-data-wrapper .listing-tables tr:last-child th,
.remaining-amount-data-wrapper .listing-tables tr:last-child td {
    border-bottom: none;
}

.body-content.content-page {
    padding: 0;
}

.content-page h1,
.content-page h2,
.content-page h3,
.content-page h4,
.content-page h5,
.content-page h6 {
    font-size: 25px;
    line-height: 1.3em;
    margin: 0 0 15px;
    color: #fff;
}

.content-page ul li,
.content-page ol li,
.content-page p {
    font-size: 16px;
    line-height: 1.3em;
    margin: 0 0 15px;
    color: #fff;
    font-family: var(--avenext);
}

.content-page p a {
    color: var(--primary);
    text-decoration: underline;
}

.not-contributed {
    margin: 80px auto 0;
}

.not-contributed .btns-wrapper {
    margin-top: 25px;
}

.not-contributed .btns-wrapper .btn {
    margin: 0 8px;
}

.subdesc-wrapper {
    padding: 25px 25px 15px 25px;
    background: #fff;
    border-radius: 25px;
}

.panel-wrapper {
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.primary-panel {
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    max-width: 420px;
    padding: 0 20px 15px;
    position: relative;
    width: 420px;
}

.secondary-panel {
    display: flex;
    flex: 1 1;
    padding: 0 15px 0;
    position: relative;
    transition: all .5s ease 0s;
    width: 100%;
    max-width: 60%;
}

.distribute-nominations-list {
    margin: 8px 0 8px 10px;
    width: 100%;
}

.distribute-nominations-list .accordion-button:not(.collapsed) {
    color: #000;
}

.distribute-nominations-list .accordion-item {
    border-radius: 15px;
}

.distribute-nominations-list .accordion-header {
    padding: 15px 15px;
    font-size: 18px;
    font-weight: 400;
    border: none;
}

.distribute-nominations-list .accordion-header .accordion-button:before {
    position: absolute;
    top: 0;
    right: 0;
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--secondary-color);
}

.distribute-nominations-list .accordion-header .accordion-button[aria-expanded="false"]:before {
    content: "\f054";
}